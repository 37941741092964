import React from 'react';
import '../styles/FooterComponent.css';

const FooterComponent = () => {
  return (
    <div className="footer_container">
      <div className="footer_content">
        <div className="footer_left">
          <p className="footer_text">
            &copy; 2012-2021 Vista Miami Beach
          </p>
          <a href="#" className="footer_link_blue">Seller of Travel ST39568.</a>
        </div>
        <div className="footer_right">
          <a href="#" className="footer_link">Privacidad</a>
          <a href="#" className="footer_link">Términos</a>
          <a href="#" className="footer_link">Nosotros</a>
          <a href="#" className="footer_link">Créditos</a>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
