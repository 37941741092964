import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import MainSection from "./MainSection";
import TravelComponent from "./TravelComponent";
import BenefitsComponent from "./BenefitsComponent";
import CruiseComponent from "./CruiseComponent";
import ReservationComponent from "./ReservationComponent";
import DreamsComponent from "./DreamsComponent";
import VideoComponent from "./VideoComponent";
import DestinationsComponent from "./DestinationsComponent";
import FooterComponent from "./FooterComponent";
import ExpertProfile from "./ExpertProfile";
import WhyChooseUs from "./WhyChooseUs";
import TestimonialComponent from "./TestimonialComponent";
import { defaultUser } from "../context/AppConstants";
const Wrapper = () => {
  const { usuario } = useParams();
  const { user, setUser } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      console.log(usuario);
      try {
        const response = await fetch(
          `https://bombazoo.com/api/visa/clave/${usuario}`
        );
        const data = await response.json();
        if (data.ok) {
          const user = data.visa;
          console.log(user);
          setUser({
            ...user,
            usuario: user.clave,
            imagen: user.url_imagen,
          });
        } else {
          setUser(defaultUser);
        }
      } catch (error) {
        setUser(defaultUser);
        console.error("Error fetching user data:", error);
      }
    };

    if (!user || user.usuario !== usuario) {
      fetchUserData();
    }
  }, [usuario]);
  return (
    <div className="App">
      <Navbar />
      <MainSection />
      <TravelComponent />
      <BenefitsComponent />
      <CruiseComponent />
      <ReservationComponent />
      <DreamsComponent />
      <VideoComponent />
      <TestimonialComponent />
      <WhyChooseUs />
      <DestinationsComponent />
      <ExpertProfile />
      <FooterComponent />
    </div>
  );
};

export default Wrapper;
