import React from 'react';
import '../styles/BenefitsComponent.css';

const BenefitsComponent = () => {
  return (
    <div className="benefits_container">
      <div className="benefits_header">
        En nuestra agencia, accederás a un sinfín de beneficios exclusivos, diseñados para ofrecerte las mejores experiencias a precios incomparables en cualquier otra plataforma de reservas.
      </div>
      <div className="benefits_grid">
        <div className="benefit_item">
          <img src="/hoteles.png" alt="Hoteles" className="benefit_image" />
          <p className="benefit_text"><strong>Hoteles:</strong> Aprovecha descuentos asombrosos en alojamientos de todo el planeta.</p>
        </div>
        <div className="benefit_item">
          <img src="/circuitos.png" alt="Circuitos" className="benefit_image" />
          <p className="benefit_text"><strong>Circuitos:</strong> Adquiere paquetes de desde 3 hasta 30 días en las ciudades más visitadas del mundo. ¡Personaliza el tuyo aquí!</p>
        </div>
        <div className="benefit_item">
          <img src="/tours.png" alt="Tours" className="benefit_image" />
          <p className="benefit_text"><strong>Tours:</strong> Explora ciudades y maravillas naturales con nuestros tours a tarifas especiales.</p>
        </div>
        <div className="benefit_item">
          <img src="/renta_carros.png" alt="Renta de Carros" className="benefit_image" />
          <p className="benefit_text"><strong>Renta de Carros:</strong> Alquila tu vehículo ideal con precios reducidos.</p>
        </div>
        <div className="benefit_item">
          <img src="/cruceros.png" alt="Cruceros" className="benefit_image" />
          <p className="benefit_text"><strong>Cruceros:</strong> Disfruta de ofertas inigualables para navegar por aguas cristalinas.</p>
        </div>
        <div className="benefit_item">
          <img src="/vuelos.png" alt="Vuelos" className="benefit_image" />
          <p className="benefit_text"><strong>Vuelos:</strong> Vuela con comodidad sin gastar de más.</p>
        </div>
      </div>
    </div>
  );
};

export default BenefitsComponent;
