const defaultUser = {
  nombre: "Carlos Fernandez",
  descripcion_miami: `¡Bienvenidos al futuro de tus viajes! Soy un agente de viajes especializado en destinos como Orlando y Miami. Con mi experiencia y conocimiento, te ofrezco acceso exclusivo a lo más nuevo en la industria hotelera: paquetes de viaje subsidiados hasta en un 80% por los hoteles que te hospedarán.<br/>
Imagina disfrutar de los mejores parques temáticos en Orlando o las playas vibrantes de Miami sin preocuparte por el costo. Gracias a nuestros acuerdos únicos, puedes experimentar lujo y comodidad a precios incomparables. Cada viaje se convierte en una aventura asequible y extraordinaria.<br/>
Confía en mí para planificar tu próxima escapada a Orlando o Miami. Con paquetes de viaje increíblemente subsidiados, te garantizo que vivirás una experiencia inolvidable sin romper el banco. ¡Prepárate para descubrir la magia de viajar con las mejores ofertas del mercado!`,
  numero: "3057781450",
  indicativo: "1",
  correo: "info@escapesdelujo.com",
  imagen:
    "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
  pais: "Estados Unidos",
};

module.exports = {
  defaultUser,
};
