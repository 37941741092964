import React from "react";
import "../styles/VideoComponent.css";
import WhatsAppButton from "./WhatsAppButton";

const VideoComponent = () => {
  return (
    <div className="video_container">
      <video
        src="/video2.mp4"
        controls
        className="testimonial-video"
        poster="./place.png"
      ></video>
      <WhatsAppButton
        text="Hola, quiero reservar mi próximo viaje desde visitamiami.com"
        buttonText={
          <button className="reserve_button">RESERVA TU VIAJE</button>
        }
        className="quote-button-link"
      />
    </div>
  );
};

export default VideoComponent;
