import React from 'react';
import '../styles/ReservationComponent.css';

const ReservationComponent = () => {
  return (
    <div className="reservation_container">
      <div className="reservation_header">
        Reserva viajes en todo el mundo.<br />
        Cuando y donde quieras
      </div>
      <div className="reservation_content">
        <img src="/family_image.png" alt="Family" className="reservation_image" />
        <div className="reservation_text">
          Creemos en la personalización. Te ayudamos a crear tu propio paquete de viaje a medida, con opciones que se adaptan a tus gustos y necesidades.<br /><br />
          Desde el vuelo de tu avión, renta de autos, traslados y asesoría de visas, estamos aquí para hacer que tu viaje sea inolvidable en todos los aspectos.
        </div>
      </div>
    </div>
  );
};

export default ReservationComponent;
