import React from "react";
import "../styles/WhyChooseUs.css";
import WhatsAppButton from "./WhatsAppButton";

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us">
      <div className="content">
        <div className="left-container">
          <img src="/logo.png" alt="Logo" className="logo" />
          <h1>¿Por qué elegirnos?</h1>
          <div className="benefits">
            <div className="benefit-card">
              <img src="Icon.svg" alt="Agentes calificados" className="icon" />
              <div className="text">
                <h2>Agentes calificados</h2>
                <p>
                  Nuestros agentes le brindarán un trato personalizado. Siempre
                  disponibles y en línea.
                </p>
              </div>
            </div>
            <div className="benefit-card">
              <img src="/Icon-1.svg" alt="Destinos seguros" className="icon" />
              <div className="text">
                <h2>Destinos seguros</h2>
                <p>
                  Su seguridad es nuestra prioridad. Ofrecemos los paquetes más
                  exclusivos y seguros.
                </p>
              </div>
            </div>
            <div className="benefit-card">
              <img src="/Icon-2.svg" alt="Mejores precios" className="icon" />
              <div className="text">
                <h2>Mejores precios</h2>
                <p>
                  Al crédito y al contado. Las mejores vacaciones a los mejores
                  precios del mercado.
                </p>
              </div>
            </div>
            <div className="benefit-card">
              <img
                src="/Icon-3.svg"
                alt="Lugares exclusivos"
                className="icon"
              />
              <div className="text">
                <h2>Lugares exclusivos</h2>
                <p>
                  Nuestras experiencias de viaje son únicas. Descubre los
                  lugares más increíbles junto a nosotros.
                </p>
              </div>
            </div>
            <div className="benefit-card">
              <img src="/Icon-4.svg" alt="Viajes familiares" className="icon" />
              <div className="text">
                <h2>Viajes familiares</h2>
                <p>
                  Disponemos de planes vacacionales en familia, con grandes
                  facilidades de pago.
                </p>
              </div>
            </div>
            <div className="benefit-card">
              <img
                src="/Icon-5.svg"
                alt="Ofertas y descuentos"
                className="icon"
              />
              <div className="text">
                <h2>Ofertas y descuentos</h2>
                <p>
                  Obtén numerosas ofertas y descuentos en nuestras redes
                  sociales. Precios increíbles disponibles.
                </p>
              </div>
            </div>
          </div>
          <WhatsAppButton
            text="Hola, quiero reservar mi próximo viaje desde visitamiami.com"
            buttonText={
              <button className="discover-button">Descubre Cómo</button>
            }
            className="quote-button-link"
          />
        </div>
        <div className="right-container">
          <img
            src="/mujer.png"
            alt="Mujer con maleta"
            className="highlight-image"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
