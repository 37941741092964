import React from 'react';
import '../styles/DreamsComponent.css';

const DreamsComponent = () => {
  return (
    <div className="dreams_container">
      <div className="dreams_header">
        Transforma tus sueños de viaje en realidad.
      </div>
      <div className="dreams_content">
        <div className="dreams_left">
          <img src="/left_image.png" alt="Travel Dreams" className="dreams_left_image" />
        </div>
        <div className="dreams_right">
          <div className="dreams_text">
            <strong>¿Por qué son más bajos los costos?</strong><br /><br />
            Dependiendo del viaje que desees realizar, contamos con acceso a paquetes vacacionales que no incluyen los precios elevados de publicidad que pagan las plataformas comerciales.<br /><br />
            Lo único que tienes que hacer es cotizar tu próxima viaje y entrar en contacto con tu agente.
          </div>
          <img src="/plane.png" alt="Plane" className="dreams_plane" />
        </div>
      </div>
    </div>
  );
};

export default DreamsComponent;
