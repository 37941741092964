import React from 'react';
import '../styles/TestimonialComponent.css';

const TestimonialComponent = () => {
  return (
    <div className="testimonial-container">
      <div className="testimonial-content">
        <div className="testimonial-left">
          <img src="/tren.png" alt="Orlando to Miami" className="testimonial-main-image" />
        </div>
        <div className="testimonial-right">
          <h2>Testimonios</h2>
          <h1>Nuestros clientes</h1>
          <p className="testimonial-quote">
            “Estamos muy agradecidos con Vista Miami Beach por estos espectaculares días. Y eso de obsequiarnos el alquiler de un auto para movilizarnos es una idea genial ¡Nos encantó el paseo en limosina!”
          </p>
          <div className="testimonial-author">
            <div className='fot_foto'>
                <img src="/Background.png" alt="Familia" className="author-image2" />
                <img src="/author_image.png" alt="Familia" className="author-image" />
            </div>
            <div className="author-info">
              <div className="author-rating">
                {[...Array(5)].map((_, i) => (
                  <span key={i} className="star">★</span>
                ))}
              </div>
              <p className="author-name">Familia Rodríguez</p>
              <p className="author-country">Argentina</p>
            </div>
          </div>
        </div>
      </div>
      <img src="/plant_left.png" alt="Planta izquierda" className="testimonial-plant-left" />
      <img src="/plant_right.png" alt="Planta derecha" className="testimonial-plant-right" />
      <svg className="testimonial-diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#ffffff" fillOpacity="1" d="M0,320L1440,320L1440,250L0,320Z"></path>
      </svg>
    </div>
  );
};

export default TestimonialComponent;
