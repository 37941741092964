import React from 'react';
import '../styles/DestinationsComponent.css';

const destinations = [
  { name: 'Cancún', image: 'cancun.png' },
  { name: 'Miami', image: 'miami.png' },
  { name: 'Orlando', image: 'orlando.png' },
  { name: 'Bahamas', image: 'bahamas.png' },
  { name: 'Punta Cana', image: 'puntacana.png' },
];

const DestinationsComponent = () => {
  return (
    <div className="destinations_container">
        <div className='destinarios_cont'>
      <h2 className="destinations_title">Elige tu destino favorito</h2>
      <div className="destinations_grid">
        {destinations.map((destination, index) => (
          <div key={index} className="destination_item">
            <img src={destination.image} alt={destination.name} className="destination_image" />
            <p className="destination_name">{destination.name}</p>
          </div>
        ))}
      </div>
      <p className="destinations_footer">¡Y cientos de destinos más!</p>
      </div>
    </div>
  );
};

export default DestinationsComponent;
