import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../styles/MainSection.css";

const images = [
  "/Beijing.jpg",
  "/Cancun.jpg",
  "/Chichen.jpg",
  "/China.jpg",
  "/Egipto.jpg",
  "/Grecia.jpg",
  "/Italia.jpg",
  "/Londres.jpg",
  "/Miami.jpg",
  "/NewYork.jpg",
  "/Orlando.jpg",
  "/Paris.jpg",
  "/Peru.jpg",
  "/Roma.jpg",
  "/Sidney.jpg"
];

const titles = [
  "Beijing",
  "Cancún",
  "Chichen Itzá",
  "China",
  "Egipto",
  "Grecia",
  "Italia",
  "Londres",
  "Miami",
  "New York",
  "Orlando",
  "París",
  "Perú",
  "Roma",
  "Sídney"
];

const subtitles = [
  "La ciudad prohibida y más.",
  "Playas paradisíacas.",
  "Maravilla del mundo.",
  "Historia y modernidad.",
  "Misterios antiguos.",
  "Cuna de la civilización.",
  "Arte y cultura.",
  "Historia y modernidad.",
  "Playas y vida nocturna.",
  "La gran manzana.",
  "Diversión en familia.",
  "Romanticismo puro.",
  "Riqueza cultural.",
  "Historia viva.",
  "Ciudad icónica."
];

const MainSection = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main_container">
      <AnimatePresence>
        <motion.div
          key={index}
          className="main_content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{ backgroundImage: `url(${images[index]})` }}
        >
          <motion.div
            initial={{ y: -150, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 1 }}
            className="text_container"
          >
            <h1 className="main_title">{titles[index]}</h1>
            <h2 className="main_subtitle">{subtitles[index]}</h2>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default MainSection;
