import React from 'react';
import '../styles/TravelComponent.css';

const TravelComponent = () => {
  return (
    <div className="travel_container">
      <div className="travel_inner_container">
        <div className="center_content">
          <img src="/imagen_centro.png" alt="Circles" className="travel_circles" />
          <img src="/imagen_texto.png" alt="Text" className="travel_text_image" />
        </div>
        <img src="/imagen_rama_1.png" alt="Left Leaf" className="travel_leaf travel_leaf_left" />
        <img src="/imagen_rama_2.png" alt="Right Leaf" className="travel_leaf travel_leaf_right" />
      </div>
    </div>
  );
};

export default TravelComponent;
