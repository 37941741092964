import React from 'react';
import '../styles/CruiseComponent.css';

const CruiseComponent = () => {
  return (
    <div className="cruise_container">
      <img src="/crucero.png" alt="Crucero" className="cruise_image" />
    </div>
  );
};

export default CruiseComponent;
